import React, { useEffect, useState } from 'react'
import { View, useSx } from 'dripsy'
import { TextField } from 'app/components/text-field'
import { Space } from 'app/components/space'
import { Button } from 'app/components/button'
import { ToastMessage } from 'app/redux/reducers/toasts'
import { Link } from 'app/components/link'
import { AccountLayout } from '../account-layout'
import { isValidEmail } from 'app/util/helper'
import { useRouter } from 'solito/router'
import { connect, useDispatch } from 'react-redux'
import * as userAction from '../../../redux/actions/user.action'
import * as tokenAction from '../../../redux/actions/token.action'
import * as toastAction from '../../../redux/actions/toast.action'
import { useNotification } from 'app/provider/notification-context'
import useFirebaseEvents from 'app/redux/hooks/use-firebase-events'
import { FIREBASE_LOGIN_EVENT } from 'app/redux/hooks/use-firebase-events/firebase-constants'
import { I18Text, useI18String } from 'app/provider/i18-provider'
import { SET_TOKEN } from 'app/redux/constants'
import { createParam } from 'solito'
import { makeUseAxiosHook } from 'app/redux/store'
const { useParam } = createParam<{ requestId: string }>()
export const SignInScreen = ({
  user,
  login,
  addMessage,
}: {
  user: any
  clientConfig: any
  login: (_user: any) => any
  setToken: (token: string) => any
  addMessage: (message: ToastMessage) => any
  showWelcomePopup: () => any
}) => {
  const [requestId] = useParam('requestId')

  const dispatch = useDispatch()
  const sx = useSx()
  const i18String = useI18String()
  const { expoPushToken } = useNotification()
  const Router = useRouter()
  const { replace } = Router
  const logEvent = useFirebaseEvents()

  const googleLoginSuccess = (data: any) => {
    // setToken(data.accessToken);
  }
  const googleLoginError = () => {
    addMessage({ message: '', type: 'error' })
  }

  const [selectedEditObj, setSelectedEditObj] = useState({
    email: '',
    password: '',
  })
  const [errorObj, setErrorObj] = useState<any>({})

  const validateForm = () => {
    let errorList: any = {}

    if (!selectedEditObj.email) {
      errorList.email = i18String('Please enter your Email')
    }

    if (selectedEditObj.email && !isValidEmail(selectedEditObj.email)) {
      errorList.email = i18String('Please provide a valid email address.')
    }

    if (!selectedEditObj.password) {
      errorList.password = i18String('Please enter your password')
    }
    if (Object.keys(errorList).length == 0) {
      setErrorObj({})
      return true
    } else {
      setErrorObj(errorList)
      errorList = {}
      return false
    }
  }

  const updateValue = (key: string, value: any) => {
    setSelectedEditObj({ ...selectedEditObj, [key]: value })
  }
  const [loading, setLoading] = useState(false)

  const handleSignin = async () => {
    if (!validateForm()) return
    setLoading(true)
    login({
      email: selectedEditObj.email,
      password: selectedEditObj.password,
      strategy: 'local',
      expoToken: expoPushToken,
    })
  }

  useEffect(() => {
    if (user) {
      if (user.error) {
        setLoading(false)
        if (user.error.fieldKey === 'email') {
          setErrorObj({ email: user?.error?.message })
        } else setErrorObj({ password: user?.error?.message })
        return
      }
      if (user?._id) {
        if (loading) {
          logEvent({
            eventName: FIREBASE_LOGIN_EVENT,
            additionalParams: {
              email: selectedEditObj.email,
            },
          })
          setLoading(false)
          replace('/dashboard')
        } else {
          setLoading(false)
          replace('/dashboard')
        }
      }
    }
  }, [user])

  // aws marketplace
  const [, getDetails] = makeUseAxiosHook('default', {
    manual: true,
  })({
    url: `/api/v1/aws-subscribe-request-logs/${requestId}`,
    method: 'GET',
  })

  useEffect(() => {
    if (requestId) {
      setLoading(true)
      getDetails().then((res) => {
        setLoading(false);
        if (res?.data?.authResponse) {
          dispatch({
            type: SET_TOKEN,
            payload: res?.data?.authResponse.token,
          })
        }
      })
    }
  }, [requestId])

  return (
    <AccountLayout>
      <TextField
        label={i18String('Email.*')}
        innerInputLabel
        style={{
          marginBottom: 8,
        }}
        autoCapitalize="none"
        onChangeText={(value) => updateValue('email', value)}
        helperText={errorObj.email}
        error={!!errorObj.email}
        value={selectedEditObj.email}
      />
      <TextField
        label={i18String('Password*')}
        innerInputLabel
        type="password"
        value={selectedEditObj.password}
        helperText={errorObj.password}
        error={!!errorObj.password}
        onChangeText={(value) => updateValue('password', value)}
        style={{
          marginBottom: 8,
        }}
      />
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: 8,
          justifyContent: 'flex-end',
        }}
      >
        {/* <View
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <CheckBox checked={true} />
          <Text style={{ marginLeft: 4 }}>Remember me</Text>
        </View> */}
        <Link
          variantType="text"
          isNextLink
          href="/account/forgotpassword"
          style={sx({
            width: [110],
            paddingX: 0,
            marginY: 5,
          })}
          textColor="#000"
        >
          <I18Text onlyString>Forgot Password?</I18Text>
        </Link>
      </View>
      <Button
        style={{
          height: 35,
          marginBottom: 8,
        }}
        loading={loading}
        onPress={handleSignin}
      >
        <I18Text onlyString>Sign in</I18Text>
      </Button>
      {/* <GoogleSignIn
        onSuccess={googleLoginSuccess}
        onError={googleLoginError}
        buttonText="Sign in with Google"
        startText=""
      /> */}
      <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
        <I18Text>Don’t have an account?</I18Text>
        <Link
          variantType="text"
          isNextLink
          href="/account/signup"
          style={sx({
            width: [45, 'auto'],
            marginLeft: 4,
            paddingX: 0,
            height: 40,
          })}
          textColor="#000"
        >
          <I18Text onlyString>Sign up</I18Text>
        </Link>
      </Space>
    </AccountLayout>
  )
}

const mapStateToProps = ({ user, clientConfig }: any) => ({
  user,
  clientConfig,
})

export const SignIn = connect(mapStateToProps, {
  ...userAction,
  ...tokenAction,
  ...toastAction,
})(SignInScreen)
